import {
  connectorsForWallets,
  midnightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  trustWallet,
  ledgerWallet,
  injectedWallet,
  walletConnectWallet,
  metaMaskWallet,
  coinbaseWallet,
  rabbyWallet,
  phantomWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet],
  [publicProvider()]
);

const WALLET_CONNECT_PROJECT_ID = "55700d2019fab98e65ed4e2da104c86a";

export const WalletWrapper = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  // ! Disable Trust Wallet on mobile cuz rainbowkit is broken on our current version but upgrading requires a lot of work so we'll just disable it for now
  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;

  const connectors = connectorsForWallets([
    {
      groupName: "Popular",
      wallets: [
        injectedWallet({ chains }),
        metaMaskWallet({ chains, projectId: WALLET_CONNECT_PROJECT_ID }),
        coinbaseWallet({ appName: "Sweet | Staking", chains }),
      ],
    },
    {
      groupName: "More",
      wallets: [
        ...(isMobile ? [] : [trustWallet({ chains })]),
        ledgerWallet({ projectId: WALLET_CONNECT_PROJECT_ID, chains }),
        rabbyWallet({ chains }),
        phantomWallet({ chains }),
        walletConnectWallet({
          projectId: WALLET_CONNECT_PROJECT_ID,
          chains,
        }),
      ],
    },
  ]);

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
    webSocketProvider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={midnightTheme()}
        modalSize="compact"
      >
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};
