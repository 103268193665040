import type { AppProps } from "next/app";
import { Analytics } from "@vercel/analytics/react";
import { WalletWrapper } from "../components/common/WalletWrapper";

import "../styles/globals.css";
import "@rainbow-me/rainbowkit/styles.css";
import { Toaster } from "react-hot-toast";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <WalletWrapper>
      <>
        <Toaster
          position="bottom-right"
          toastOptions={{
            style: {
              background: "#1b232d",
              color: "#fff",
              fontSize: "14px",
            },
          }}
        />
        <Component {...pageProps} />
        <Analytics />
      </>
    </WalletWrapper>
  );
}
